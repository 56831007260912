<template>
  <v-row>
    <v-col
      cols="8"
      md="4"
    >
      <v-alert
        v-if="created"
        type="success"
      >
        Enregistrement réussi!
      </v-alert>
      <v-form>
        <v-text-field
          v-model="birthdayReminder.surname"
          :prepend-inner-icon="icons.mdiAccountOutline"
          label="Prénom"
          outlined
          dense
          placeholder="Prénom"
        ></v-text-field>

        <v-text-field
          v-model="birthdayReminder.name"
          :prepend-inner-icon="icons.mdiEmailOutline"
          label="Nom"
          outlined
          dense
          placeholder="Nom"
        ></v-text-field>

        <v-text-field
          v-model="birthdayReminder.birthdayDate"
          :prepend-inner-icon="icons.mdiCellphone"
          label="Date de naissance"
          type="date"
          outlined
          dense
        ></v-text-field>

        <v-btn
          color="primary"
          @click="handleNewBirthday"
        >
          Enregistrer
        </v-btn>
        <!--<v-btn
          type="reset"
          outlined
          class="mx-2"
        >
          Reset
        </v-btn>-->
      </v-form>
    </v-col>
    <!-- basic -->

    <!-- fixed header -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Les Anniversaires à ne pas oublier</v-card-title>
        <v-card-text>
          Ne t'inquiètes pas, on peut t'envoyer une notification pour t'aider
        </v-card-text>

        <v-simple-table
          height="250"
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-uppercase">
                  Nom
                </th>
                <th class="text-center text-uppercase">
                  Prénom
                </th>
                <th class="text-center text-uppercase">
                  Date de naissance
                </th>
                <th class="text-center text-uppercase">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="birthreminder in birthdayReminders"
                :key="`${birthreminder.name}${Math.random()}`"
              >
                <td class="text-center">
                  {{ birthreminder.name }}
                </td>
                <td class="text-center">
                  {{ birthreminder.surname }}
                </td>
                <td class="text-center">
                  {{ birthreminder.birthdayDate }}
                </td>
                <td>
                  <v-btn
                    class="text-center"
                    fab
                    small
                    @click="onButtonClick()"
                  >
                    <v-icon size="30">
                      {{ icons.mdiCloseOutline }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiAccountOutline, mdiEmailOutline, mdiCellphone, mdiLockOutline, mdiCloseOutline,
} from '@mdi/js'
import BirthdayReminder from '@/models/bullets/birthdayReminder'

export default {

  data() {
    return {
      birthdayReminders: null,
      birthdayReminder: new BirthdayReminder(),
    }
  },
  computed: {
    created() {
      return this.$store.state.birthdayreminder.status.created
    },
  },
  created() {
    this.loadAllBirthdayReminders()
  },
  setup() {
    return {
      // icons
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
        mdiCloseOutline,
      },
    }
  },
  methods: {
    handleNewBirthday() {
      if (this.birthdayReminder.name && this.birthdayReminder.surname && this.birthdayReminder.birthdayDate) {
        this.$store.dispatch('birthdayreminder/new', this.birthdayReminder).then(
          () => {
            this.birthdayReminder = new BirthdayReminder('', '', '')
          },
          error => {
            this.message = (error.response && error.response.data)
                || error.message
                || error.toString()
          },
        )
      }
    },
    loadAllBirthdayReminders() {
      this.$store.dispatch('birthdayreminder/getAll').then(
        response => {
          this.birthdayReminders = response
        },
        error => {
          this.message = (error.response && error.response.data)
                || error.message
                || error.toString()
        },
      )
    },
  },
}
</script>
